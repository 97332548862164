<template>
  <div class="container">
    <div class="left">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="create.referenceCode" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory.selected"
            :options="territory.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="territory"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('territory')">{{
            errors.first("territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group 1</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customerGroup.selected"
            :options="customerGroup.options"
            :show-labels="false"
            multiple
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="code"
            name="customerGroup"
            v-validate="'required'"
            @input="selectedCustomerGroup"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.code }}</span>
              </div>
            </template>
          </multiselect>
          <span
            class="text-danger text-sm"
            v-show="errors.has('customerGroup')"
            >{{ errors.first("customerGroup") }}</span
          >
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customer.selected"
            :options="customer.options"
            multiple
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="100"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="id"
            label="name"
            name="customer"
            @search-change="handlerSearchCustomer"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('customer')">{{
            errors.first("customer")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <flat-pickr
            :config="configDatePicker"
            v-model="create.dateApply"
            placeholder="Valid From"
            style="width: 100%"
            v-validate="'required'"
            name="dateApply"
          />
          <span class="text-danger text-sm" v-show="errors.has('dateApply')">{{
            errors.first("dateApply")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Notes</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            class="w-full"
            v-model="create.notes"
            v-validate="'required'"
            name="notes"
          />
          <span class="text-danger text-sm" v-show="errors.has('notes')">{{
            errors.first("notes")
          }}</span>
        </div>
      </div>
      <div class="vx-row" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3">
          <label
            >Attachment
            <small style="color: red"
              >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
            ></label
          >
        </div>
        <div class="vx-col sm:w-2/3">
          <div class="vx-col w-1/2">
            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 10%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div
        class="vx-row margin-btm space-x-4"
        style="width: 100%; margin-left: 0%"
      >
        <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
          Save
        </vs-button>

        <vs-button @click="closeDetail()" icon-pack="feather" icon="icon-x">
          Close
        </vs-button>
      </div>
    </div>

    <div class="right">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Evaluation Period Data</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="create.evaluation_period"
            type="number"
            name="evaluationPeriod"
            v-validate="'required'"
            min="1"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('evaluationPeriod')"
            >{{ errors.first("evaluationPeriod") }}</span
          >
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>% Growth</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            type="number"
            v-model="create.growth"
            v-validate="'required'"
            name="growth"
            min="1"
          />
          <span class="text-danger text-sm" v-show="errors.has('growth')">{{
            errors.first("growth")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Multiplier</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            type="number"
            name="multiplier"
            v-model="create.multiplier"
            v-validate="'required'"
            min="1"
          />
          <span class="text-danger text-sm" v-show="errors.has('multiplier')">{{
            errors.first("multiplier")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Exclude SO Type</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="soType.selected"
            :options="soType.options"
            :show-labels="false"
            multiple
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="Code"
            name="code"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.Code }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Reason</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="reason.selected"
            :options="reason.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="reason"
            name="reason"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.reason }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.reason }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{
            errors.first("reason")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  components: { flatPickr },
  props: {
    id: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detail: false,
      data: {},
      attachment: "",
      create: {
        referenceCode: null,
        dateApply: "",
        validFroms: "",
        amount: null,
        notes: null,
        evaluation_period: 1,
        growth: 1,
        multiplier: 1,
      },
      territory: {
        selected: {},
        options: [],
      },
      customer: {
        selected: [],
        options: [],
        search: "",
      },
      customerGroup: {
        selected: [],
        options: [],
      },
      reason: {
        selected: {},
        options: [],
      },
      soType: {
        selected: [],
        options: [],
      },

      customerData: {},
      calculateData: {},

      configDatePicker: {
        dateFormat: "d F Y",
        minDate: "today",
      },
      isTerritory: true,
      Ids: 0,
      fileAttachment: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.$emit("closeDetail", params);
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    getAllTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/multiple-territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    selectedCustomerGroup() {
      this.customer.selected = [];
      this.customer.options = [];
      this.getAllCustomerByTerritory();
    },

    getAllCustomerByTerritory(query) {
      if (this.territory.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return;
      }
      if (this.customerGroup.selected == null) {
        this.customerGroup.selected = {
          ID: 0,
        };
      }
      if (this.customerGroup.selected.ID == 0) {
        this.customerGroup.selected.ID = 0;
      }

      this.$http
        .get("/api/v1/master/customer-by-territory", {
          params: {
            territory_id: this.territory.selected.id,
            customer_group_id: this.customerGroup.selected.ID,
            search: query ? query : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            if (resp.data.length > 0) {
              this.customer.options = [
                { id: 0, code: "ALL", name: "ALL" },
                ...resp.data,
              ];
            } else {
              this.customer.options = [];
            }
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handlerSearchCustomer(searching) {
      this.customer.options = [];
      this.search = searching;
      this.getAllCustomerByTerritory(searching);
    },
    getAllReason() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/reason", {
          params: {
            reason_group: "Credit Limit",
          },
        })
        .then((resp) => {
          this.reason.options = resp.data.records;
          this.$vs.loading.close();
        });
    },
    getAllCustomerGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            resp.data.records.unshift({
              ID: 0,
              code: "All",
            });
            this.customerGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllSoType() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/sales-order-type").then((resp) => {
        this.soType.options = resp.data.records;
        this.soType.options = [{ ID: 0, Code: "-" }, ...this.soType.options];
        this.$vs.loading.close();
      });
    },

    handleSubmit() {
      if (this.$refs.file.files[0] != null) {
        const file = this.$refs.file.files[0];
        const ext = file.name.split(".").pop();
        if (
          ext != "jpg" &&
          ext != "jpeg" &&
          ext != "png" &&
          ext != "pdf" &&
          ext != "xlsx" &&
          ext != "xls" &&
          ext != "doc" &&
          ext != "docx"
        ) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Attachment must be jpg, jpeg, png, pdf, xlsx, xls, doc, docx",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
      this.$validator.validateAll().then((result) => {
        if (this.create.evaluation_period <= 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Evaluation Period must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (this.create.growth < 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Growth must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (this.create.multiplier <= 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Multiplier must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (result) {
          this.Submit();
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Please complete the form",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    Submit() {
      this.create.validFroms = moment(this.create.dateApply).format(
        "YYYY-MM-DD"
      );
      console.log("eeeeeeeeeeee", this.create.dateApply);
      this.$vs.loading();

      this.$http
        .post("/api/v1/master/credit-limit-regulers-id", {
          id: this.id,

          territory_id: this.territory.selected.id,
          customer_group_id_1: this.customerGroup.selected.ID,
          valid_from: this.create.validFroms,
          note: this.create.notes,
          evaluation_period: Number(this.create.evaluation_period),
          growth: Number(this.create.growth),
          multiplier: Number(this.create.multiplier),
          exclude_so_type: this.soType.selected
            ? this.soType.selected
            : [{ ID: 0, Code: "-" }],
          reason_id: this.reason.selected ? this.reason.selected.ID : null,
          reason_code: this.reason.selected ? this.reason.selected.code : "",
          reason_name: this.reason.selected ? this.reason.selected.reason : "",
          customer: this.customer.selected,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });

            this.$vs.loading.close();
            // this.handleSubmitAttachment();
            this.closeDetail(true);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-regulers-id-show", {
          params: {
            id: this.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.getAllTerritory();
            this.getAllReason();
            this.getAllCustomerGroup();
            this.getAllSoType();
            this.data = resp.data;
            this.create.referenceCode = this.data.code;
            this.territory.selected.name = this.data.territory_name;
            this.territory.selected.id = this.data.territory_id;

            //convert data on this.data.credit_limit_reguler_customer where ID convert to id
            this.data.credit_limit_reguler_customer.forEach((element) => {
              element.id = element.ID;
              element.code = element.CustomerCode;
              element.name = element.CustomerName;
            });

            this.customer.selected = this.data.credit_limit_reguler_customer
              ? this.data.credit_limit_reguler_customer.length > 0
                ? this.data.credit_limit_reguler_customer
                : [{ code: "", name: "", id: 0 }]
              : [{ code: "", name: "", id: 0 }];

            //each Code on this.data.credit_limit_reguler_customer_group convert to code
            this.data.credit_limit_reguler_customer_group.forEach((element) => {
              element.code = element.Code;
              element.name = element.Name;
            });

            this.customerGroup.selected = this.data
              .credit_limit_reguler_customer_group
              ? this.data.credit_limit_reguler_customer_group
              : [{ ID: 0, code: "All" }];

            this.soType.selected = this.data
              .credit_limit_reguler_sales_order_type
              ? this.data.credit_limit_reguler_sales_order_type.length > 0 &&
                this.data.credit_limit_reguler_sales_order_type[0]
                  .SalesOrderTypeID != 0
                ? this.data.credit_limit_reguler_sales_order_type
                : [{ ID: 0, Code: "-" }]
              : [{ ID: 0, Code: "-" }];

            console.log("Dataaaaa", this.data.valid_from);

            //convert this.data.valid_from to format date  13 March 2024 not 13 Mar 2024
            this.create.dateApply = moment(this.data.valid_from).format(
              "DD MMMM YYYY"
            );
            console.log("ooooo", this.create.dateApply);
            this.create.notes = this.data.notes;
            this.create.evaluation_period = this.data.evaluation_period;
            this.create.growth = this.data.growth;
            this.create.multiplier = this.data.multiplier;

            this.reason.selected.reason = this.data.reason_name;
            this.reason.selected.ID = resp.data.reason_id;

            if (resp.data.attachment != null) {
              resp.data.attachment.forEach((el) => {
                this.fileAttachment.push({
                  id: el.ID,
                  NameFile: el.Name,
                  PathFile: el.Url,
                });
              });
            }

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;

        for (let i = 0; i < this.file.length; i++) {
          if (
            this.file[i].type != "application/pdf" &&
            this.file[i].type != "image/jpeg" &&
            this.file[i].type != "image/jpg" &&
            this.file[i].type != "image/png" &&
            this.file[i].type != "application/msword" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            this.file[i].type != "application/vnd.ms-excel" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            this.$vs.notify({
              title: "Error",
              text: "File type not allowed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }
        }

        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
          this.newFileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        this.handleSubmitAttachment();
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = file.PathFile;

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(data, index) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Warning",
        text: "Are you sure to delete this data?",
        accept: () => {
          this.$vs.loading();

          this.$http
            .delete(
              "/api/v1/master/credit-limit-regulers-attachment/" + data.ID
            )
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Data deleted successfully",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.fileAttachment = this.fileAttachment.filter((r, i) => {
                  return i != index;
                });
                this.IDAttachment = this.IDAttachment.filter((r, i) => {
                  return r != data.id;
                });
              }
              this.$vs.loading.close();
            });
        },
      });
    },
    handleSubmitAttachment() {
      console.log(this.newFileAttachment);

      this.$vs.loading();
      if (this.newFileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.newFileAttachment.length; index++) {
          formData.append("attachments[]", this.newFileAttachment[index].File);
          formData.append("id", this.id);
        }
        this.$http
          .post("/api/v1/master/credit-limit-regulers-attachment", formData)
          .then((resp) => {
            if (resp.status == "success") {
              for (
                let index = 0;
                index < resp.data.attachment_id.length;
                index++
              ) {
                this.IDAttachment.push(resp.data.attachment_id[index]);
              }
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.newFileAttachment = [];
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
            this.$vs.loading.close();
          });
      }
    },
  },
  watch: {
    "create.territory": function (val) {
      if (val) {
        this.errors.clear();
        this.create.territory = val.id ? val.id : val.ID;
      } else {
        this.create.territory = null;
      }
    },
    "create.creditLimitRequest": function (val) {
      if (this.customerData.credit_limit !== 0) {
        // Hitung perubahan persentase
        this.percentageChange =
          ((val - this.customerData.credit_limit) /
            this.customerData.credit_limit) *
          100;

        this.create.paymentTermRequest = this.percentageChange.toFixed(2) + "%";
      }
    },
    "territory.selected": function (val) {
      if (val != null) {
        this.customer.selected = [];
        this.getAllCustomerByTerritory();
      } else {
        this.customer.selected = [];
      }
    },
    "customer.selected": function () {
      if (this.customer.selected.length > 1) {
        for (let index = 0; index < this.customer.selected.length; index++) {
          if (this.customer.selected[index].id == 0) {
            this.customer.selected.splice(index, 1);
          }
        }
      }
    },
    "soType.selected": function () {
      if (this.soType.selected.length > 1) {
        for (let index = 0; index < this.soType.selected.length; index++) {
          if (this.soType.selected[index].ID == 0) {
            this.soType.selected.splice(index, 1);
          }
        }
      }
    },
  },
};
</script>
<style>
.container {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}
</style>
