<template>
  <div class="container">
    <div class="left">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="create.referenceCode" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory.selected"
            :options="territory.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="territory"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('territory')">{{
            errors.first("territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group 1</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customerGroup.selected"
            :options="customerGroup.options"
            :show-labels="false"
            multiple
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="code"
            name="customergroup"
            v-validate="'required'"
            @input="selectedCustomerGroup"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.code }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('customergroup')"
            >customer group is required</span
          >
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customer.selected"
            :options="customer.options"
            multiple
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="500"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="id"
            label="name"
            name="customer"
            @search-change="handlerSearchCustomer"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('customer')">{{
            errors.first("customer")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <flat-pickr
            :config="configDatePicker"
            v-model="create.dateApply"
            placeholder="Valid From"
            style="width: 100%"
            v-validate="'required'"
            name="dateApply"
          />
          <span class="text-danger text-sm" v-show="errors.has('dateApply')">{{
            errors.first("dateApply")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Notes</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            class="w-full"
            v-model="create.notes"
            v-validate="'required'"
            name="notes"
          />
          <span class="text-danger text-sm" v-show="errors.has('notes')">{{
            errors.first("notes")
          }}</span>
        </div>
      </div>
      <div class="vx-row" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3">
          <label
            >Attachment
            <small style="color: red"
              >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
            ></label
          >
        </div>
        <div class="vx-col sm:w-2/3">
          <div class="vx-col w-1/2">
            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 10%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div
        class="vx-row margin-btm space-x-4"
        style="width: 100%; margin-left: 0%"
      >
        <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
          Submit
        </vs-button>

        <vs-button @click="closeDetail()" icon-pack="feather" icon="icon-x">
          Close
        </vs-button>
      </div>
    </div>

    <div class="right">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Evaluation Period Data</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="dateRangeEvaluationPeriod"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <span
            class="text-danger text-sm"
            v-show="errors.has('evaluationPeriod')"
            >{{ errors.first("evaluationPeriod") }}</span
          >
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>% Growth</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            type="number"
            name="growth"
            v-model="create.growth"
            v-validate="'required'"
            min="1"
          />
          <span class="text-danger text-sm" v-show="errors.has('growth')">{{
            errors.first("growth")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Multiplier</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            type="number"
            name="multiplier"
            v-model="create.multiplier"
            v-validate="'required'"
            min="1"
          />
          <span class="text-danger text-sm" v-show="errors.has('multiplier')">{{
            errors.first("multiplier")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Exclude SO Type</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="soType.selected"
            :options="soType.options"
            :show-labels="false"
            multiple
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="Code"
            name="salesOrderTypes"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.Code }}</span>
              </div>
            </template>
          </multiselect>
          <!-- <span
            class="text-danger text-sm"
            v-show="errors.has('salesOrderTypes')"
          >
            SO type is required
          </span> -->
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Reason</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="reason.selected"
            :options="reason.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="reason"
            name="reason"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.reason }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.reason }}</span>
              </div>
            </template>
          </multiselect>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{
            errors.first("reason")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { flatPickr, DateRangePicker },
  data() {
    return {
      detail: false,
      create: {
        referenceCode: null,
        dateApply: "",
        validFroms: "",
        amount: null,
        notes: null,
        evaluation_period: 1,
        growth: 1,
        multiplier: 1,
      },
      territory: {
        selected: null,
        options: [],
      },
      customer: {
        selected: [],
        options: [],
        search: "",
      },
      customerGroup: {
        selected: [],
        options: [],
      },
      reason: {
        selected: null,
        options: [],
      },
      soType: {
        selected: [],
        options: [],
      },

      customerData: {},
      calculateData: {},

      configDatePicker: {
        dateFormat: "d F Y",
        minDate: "today",
      },
      isTerritory: true,
      Ids: 0,
      dateRangeEvaluationPeriod: {
        startDate: "",
        endDate: "",
      },
      fileAttachment: [],
    };
  },
  mounted() {
    //   this.getCustomer();
    this.getAllTerritory();
    this.getAllReason();
    this.getAllCustomerGroup();
    this.getAllSoType();
  },
  methods: {
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.$emit("closeDetail", params);
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    getAllTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/multiple-territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    selectedCustomerGroup() {
      this.customer.selected = [];
      this.customer.options = [];
      this.getAllCustomerByTerritory();
    },

    getAllCustomerByTerritory(query) {
      if (this.territory.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return;
      }
      if (this.customerGroup.selected == null) {
        this.customerGroup.selected = {
          ID: 0,
        };
      }
      if (this.customerGroup.selected.ID == 0) {
        this.customerGroup.selected.ID = 0;
      }
      let customerGroup = [];
      if (this.customerGroup.selected.length > 0) {
        for (
          let index = 0;
          index < this.customerGroup.selected.length;
          index++
        ) {
          if (this.customerGroup.selected[index].ID != 0) {
            customerGroup.push(this.customerGroup.selected[index].ID);
          }
        }
      }

      this.$http
        .get("/api/v1/master/customer-by-territory", {
          params: {
            territory_id: this.territory.selected.id,
            customer_group_id: customerGroup,
            search: query ? query : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            if (resp.data.length > 0) {
              this.customer.options = [
                { id: 0, code: "ALL", name: "ALL" },
                ...resp.data,
              ];
            } else {
              this.customer.options = [];
            }
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handlerSearchCustomer(searching) {
      this.customer.options = [];
      this.search = searching;
      this.getAllCustomerByTerritory(searching);
    },
    getAllReason() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/reason", {
          params: {
            reason_group: "Credit Limit",
          },
        })
        .then((resp) => {
          this.reason.options = resp.data.records;
          this.$vs.loading.close();
        });
    },
    getAllCustomerGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            resp.data.records.unshift({
              ID: 0,
              code: "All",
            });
            this.customerGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllSoType() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/sales-order-type").then((resp) => {
        this.soType.options = resp.data.records;
        //add id 0 for all
        this.soType.options = [{ ID: 0, Code: "-" }, ...this.soType.options];

        this.$vs.loading.close();
      });
    },

    handleSubmit() {
      if (this.fileAttachment.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "Attachment is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (this.create.evaluation_period <= 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Evaluation Period must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (this.create.growth < 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Growth must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (this.create.multiplier <= 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Multiplier must be greater than 0",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (result) {
          this.Submit();
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Please complete the form",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    Submit() {
      this.create.validFroms = moment(this.create.dateApply).format(
        "YYYY-MM-DD"
      );
      this.$vs.loading();

      this.$http
        .post("/api/v1/master/credit-limit-regulers-create", {
          territory_id: this.territory.selected.id,
          customer_group_id_1: this.customerGroup.selected,
          valid_from: this.create.validFroms,
          note: this.create.notes,
          evaluation_period: Number(this.create.evaluation_period),
          growth: Number(this.create.growth),
          multiplier: Number(this.create.multiplier),
          exclude_so_type: this.soType.selected
            ? this.soType.selected
            : [{ ID: 0, Code: "-" }],
          reason_id: this.reason.selected ? this.reason.selected.ID : null,
          reason_code: this.reason.selected ? this.reason.selected.code : "",
          reason_name: this.reason.selected ? this.reason.selected.reason : "",
          customer: this.customer.selected,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.Ids = resp.data.ids;
            this.$vs.loading.close();
            this.handleSubmitAttachment();
            this.closeDetail(true);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        });
    },
    // handleSubmitAttachment() {
    //   this.create.dateApply = moment(this.create.dateApply).format(
    //     "YYYY-MM-DD"
    //   );
    //   this.$vs.loading();
    //   if (this.$refs.file.files[0] != null) {
    //     const file = this.$refs.file.files[0];
    //     const formData = new FormData();
    //     formData.append("attachments[]", file);
    //     formData.append("id", this.Ids);
    //     this.$http
    //       .post("/api/v1/master/credit-limit-regulers-attachment", formData)
    //       .then((resp) => {
    //         if (resp.status == "success") {
    //           this.$vs.notify({
    //             color: "success",
    //             title: "Success",
    //             text: resp.message,
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-check-circle",
    //           });
    //           this.$vs.loading.close();
    //         } else {
    //           this.$vs.notify({
    //             color: "danger",
    //             title: "Failed",
    //             text: resp.message,
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-x-circle",
    //           });
    //           this.$vs.loading.close();
    //         }
    //         this.$vs.loading.close();
    //       });
    //   }
    //   this.$vs.loading.close();
    // },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format(" MMMM YYYY");
      }
      return a;
    },
    monthDiff(d1, d2) {
      var months;
      //count mounth between 2 date if 2024-01-01 - 2024-02-01 = 1 month if 2024-01-25 - 2024-02-01 = 1 month
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 1 : months + 1;
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.file.length; i++) {
          if (
            this.file[i].type != "application/pdf" &&
            this.file[i].type != "image/jpeg" &&
            this.file[i].type != "image/jpg" &&
            this.file[i].type != "image/png" &&
            this.file[i].type != "application/msword" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            this.file[i].type != "application/vnd.ms-excel" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            this.$vs.notify({
              title: "Error",
              text: "File type not allowed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }
        }

        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      console.log(file);
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    handleSubmitAttachment() {
      console.log(this.fileAttachment);

      if (this.fileAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.fileAttachment.length; index++) {
          formData.append("attachments[]", this.fileAttachment[index].File);
        }
        formData.append("id", this.Ids);

        this.$http
          .post("/api/v1/master/credit-limit-regulers-attachment", formData)
          .then((resp) => {
            if (resp.status == "success") {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.closeDetail(true);
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          });
      }
    },
  },
  watch: {
    "create.territory": function (val) {
      if (val) {
        this.errors.clear();
        this.create.territory = val.id ? val.id : val.ID;
      } else {
        this.create.territory = null;
      }
    },
    "create.creditLimitRequest": function (val) {
      if (this.customerData.credit_limit !== 0) {
        // Hitung perubahan persentase
        this.percentageChange =
          ((val - this.customerData.credit_limit) /
            this.customerData.credit_limit) *
          100;

        this.create.paymentTermRequest = this.percentageChange.toFixed(2) + "%";
      }
    },
    "territory.selected": function (val) {
      if (val != null) {
        this.customer.selected = [];
        this.getAllCustomerByTerritory();
      } else {
        this.customer.selected = [];
      }
    },
    "customer.selected": function () {
      if (this.customer.selected.length > 1) {
        for (let index = 0; index < this.customer.selected.length; index++) {
          if (this.customer.selected[index].id == 0) {
            this.customer.selected.splice(index, 1);
          }
        }
      }
    },
    "customerGroup.selected": function () {
      if (this.customerGroup.selected.length > 1) {
        for (
          let index = 0;
          index < this.customerGroup.selected.length;
          index++
        ) {
          if (this.customerGroup.selected[index].ID == 0) {
            this.customerGroup.selected.splice(index, 1);
          }
        }
      }
    },
    "soType.selected": function () {
      if (this.soType.selected.length > 1) {
        for (let index = 0; index < this.soType.selected.length; index++) {
          if (this.soType.selected[index].ID == 0) {
            this.soType.selected = [{ ID: 0, Code: "-" }];
          }
        }
      }
    },
    dateRangeEvaluationPeriod: function (val) {
      this.create.evaluation_period = this.monthDiff(
        val.startDate,
        val.endDate
      );
      console.log(this.create.evaluation_period);
    },
  },
};
</script>
<style>
.container {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}
</style>
